

@import "@mdxeditor/editor/style.css";

@import "reveal.js/dist/reveal.css";
@import "reveal.js/dist/theme/black.css";

@import "@player.js/core/dist/index.css";

/* @import "katex/dist/katex.min.css"; */

@import url('https://fonts.googleapis.com/css?family=Special+Elite');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 0 0% 3.9%;
      --card: 0 0% 100%;
      --card-foreground: 0 0% 3.9%;
      --popover: 0 0% 100%;
      --popover-foreground: 0 0% 3.9%;
      --primary: 198 93% 60%;
      --primary-focus: 198 93% 67%;
      --primary-foreground: 0 0% 100%;
	  --secondary: 180 3% 89%;
      --secondary-foreground: 0 0% 9%;
      --muted: 0 0% 96.1%;
      --muted-foreground: 0 0% 45.1%;
      --accent: 0 0% 96.1%;
      --accent-foreground: 0 0% 9%;
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;
      --border: 0 0% 89.8%;
      --input: 0 0% 89.8%;
      --ring: 0 0% 3.9%;
      --radius: 0.5rem;
      --chart-1: 12 76% 61%;
      --chart-2: 173 58% 39%;
      --chart-3: 197 37% 24%;
      --chart-4: 43 74% 66%;
      --chart-5: 27 87% 67%;

	  --secondary-background: 0, 0%, 94%;
	  --primary-background: 0, 0%, 100%;
    }

    .dark {
      --bc: 220 13.376% 100%;
      --background: 240 10% 3.9%;
      --foreground: 0 0% 98%;
      --card: 0 0% 3.9%;
      --card-foreground: 0 0% 98%;
      --popover: 0 0% 3.9%;
      --popover-foreground: 0 0% 98%;
      --primary: 228 47% 45.9%;
      --primary-focus: 228 47% 40%;

      --primary-foreground: 0 0% 100%;
      --secondary: 215 28% 17%;
	  --secondary-foreground: 180 3% 79%;
      --muted: 0 0% 14.9%;
      --muted-foreground: 0 0% 63.9%;
      --accent: 0 0% 14.9%;
      --accent-foreground: 0 0% 98%;
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;
      --border: 0 0% 14.9%;
      --input: 0 0% 14.9%;
      --ring: 0 0% 83.1%;
      --chart-1: 220 70% 50%;
      --chart-2: 160 60% 45%;
      --chart-3: 30 80% 55%;
      --chart-4: 280 65% 60%;
      --chart-5: 340 75% 55%;
	  --secondary-background: 210 30% 11%;
	  --primary-background: 185, 14%, 17%;
    }
}

@layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
}


	

.reveal-print .reveal * {
  -webkit-print-color-adjust: exact;
	transform: none !important;
}
.reveal-print .reveal {
    width: 100%;
    height: 100%;
    overflow: visible;
}
.reveal-print .reveal body {
    margin: 0 auto !important;
    border: 0;
    padding: 0;
    float: none !important;
    overflow: visible;
}
.reveal-print .reveal .nestedarrow,
.reveal-print .reveal .controls,
.reveal-print .reveal .progress,
.reveal-print .reveal .playback,
.reveal-print .reveal.overview,
.reveal-print .state-background {
    display: none !important;
}
.reveal-print .reveal pre code {
    overflow: hidden !important;
}
.reveal-print .reveal {
    width: auto !important;
    height: auto !important;
    overflow: hidden !important;
}
.reveal-print .reveal .slides {
    position: static;
    width: 100% !important;
    height: auto !important;
    zoom: 1 !important;
    pointer-events: initial;
    left: auto;
    top: auto;
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible;
    display: block;
    perspective: none;
    perspective-origin: 50% 50%;
}
.reveal-print .reveal .slides .pdf-page {
    position: relative;
    overflow: hidden;
    z-index: 1;
    page-break-after: always;
}
.reveal-print .reveal .slides .pdf-page:last-of-type {
    page-break-after: avoid;
}
.reveal-print .reveal .slides section {
    visibility: visible !important;
    display: block !important;
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    min-height: 1px;
    opacity: 1 !important;
    transform-style: flat !important;
    transform: none !important;
}
.reveal-print .reveal section.stack {
    position: relative !important;
    margin: 0 !important;
    padding: 0 !important;
    page-break-after: avoid !important;
    height: auto !important;
    min-height: auto !important;
}
.reveal-print .reveal img {
    box-shadow: none;
}
.reveal-print .reveal .backgrounds {
    display: none;
}
.reveal-print .reveal .slide-background {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: auto !important;
}
.reveal-print .reveal.show-notes {
    max-width: none;
    max-height: none;
}
.reveal-print .reveal .speaker-notes-pdf {
    display: block;
    width: 100%;
    height: auto;
    max-height: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 100;
}
.reveal-print .reveal .speaker-notes-pdf[data-layout="separate-page"] {
    position: relative;
    color: inherit;
    background-color: transparent;
    padding: 20px;
    page-break-after: always;
    border: 0;
}
.reveal-print .reveal .slide-number-pdf {
    display: block;
    position: absolute;
    font-size: 14px;
    visibility: visible;
}

.reveal-print .aria-status {
    display: none;
}




/* @import 'react-pdf/dist/Page/TextLayer.css'; */


.textLayer ::selection {
	background: rgb(147 197 253 / 0.4) !important;
	mix-blend-mode: multiply;
}






@layer base {
	
	

}

.mdxeditor-diff-source-wrapper,.cm-sourceView,.cm-editor {
  @apply h-full;
}

.mdxeditor-diff-source-wrapper{
  @apply prose;
}

.ͼo,.cm-gutterElement{
  @apply bg-transparent text-black dark:text-white;
}

.ͼo::selection{
  @apply dark:text-white dark:bg-black;
}

/* body{
  @apply dark:bg-dark
} */


.inverse-x{
  transform: scaleX(-1)
}

/* Style for the second child when parent is loading
.group:has([class*="loading"]) *:has([class*="group-loading\:hidden"]) {
	display: none;
} */


/* Style for the second child when parent is loading
.group:has([class*="loading"]) .group-loading\:lexf {
	display: block;
} */

.react-pdf__Page{
	@apply flex flex-col justify-center;
}

.react-pdf__Page__canvas{
	@apply self-center;
}


._popupContainer_1qea1_1185{
	z-index: 100;
}
.prose{
	max-width: none !important;
	font-size: inherit;
	line-height: normal;
	/* @apply text-black; 
	--tw-prose-headings: black;
	--baseTextContrast: black; */
	/* dark:text-white; */
}

.content-visibility-hidden{
	content-visibility: hidden;
}

.content-visibility-auto{
	content-visibility: auto;
}

.content-size-500{
	contain-intrinsic-size: 500px;
}

._toolbarRoot_1qea1_147{
	@apply bg-white/50;
}

._toolbarToggleItem_1qea1_189[data-disabled], ._toolbarButton_1qea1_220[data-disabled] {
	color: var(--baseBorderHover) !important;
}

.h-screen{
	height: 100vh;  
	max-height: -webkit-fill-available;
}


.rti--container{
	background-color: transparent!important;
	border: none !important;
	--rti-main: none!important;
	color: black!important;
}


.dark .mermaid .label text, .dark .mermaid span, .dark .mermaid p{
	color: white!important;
}

.dark .mermaid .node rect, .dark .mermaid .node circle, .dark .mermaid .node ellipse, .dark  .mermaid .node polygon, .dark  .mermaid .node path{
	fill: black!important;
}

.dark .mermaid .flowchart-link {
	stroke: #9370DB!important;
}


/* Custom light class to override dark mode */
.dark .light *:not(.not-superlight):not(.not-superlight *) {
	--tw-bg-opacity: 1;
	color: black;
  }

.superlight *:not(.not-superlight):not(.not-superlight *) {
	--tw-bg-opacity: 1;
	color: black !important;
  }


.reveal h1, .reveal h2, .reveal h3, .reveal h4, .reveal h5, .reveal h6 {
	color: black;
}

.dark .reveal h1, .dark .reveal h2, .dark .reveal h3, .dark .reveal h4, .dark .reveal h5, .dark .reveal h6 {
	color: white;
}

.dark .markmap {
    /* --markmap-max-width: none;
    --markmap-a-color: #0097e6;
    --markmap-a-hover-color: #00a8ff;
    --markmap-code-bg: #f0f0f0;
    --markmap-code-color: #555;
    --markmap-highlight-bg: #ffeaa7;
    --markmap-table-border: 1px solid currentColor;
    --markmap-font: 300 16px/20px sans-serif;
    --markmap-circle-open-bg: #fff; */
    --markmap-text-color: white;
    /* font: var(--markmap-font); */
    color: white!important;
}

h1 {
  @apply text-5xl font-bold;
}

h2 {
  @apply text-4xl font-bold;
}

h3 {
  @apply text-3xl font-bold;
}

h4 {
  @apply text-2xl font-bold;
}

h5 {
  @apply text-xl font-bold;
}


::-webkit-scrollbar-thumb {
  background-color: #cccccc17;
  border-radius: 10px;
}

:hover::-webkit-scrollbar-thumb {
  background-color: #aaaaaa46;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f205;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html {
  background: #202123;
  --app-height: 98dvh;
}

@media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
}




.animated-background {
    background-size: 800%;

    -webkit-animation: bg-animation 10s ease-in infinite;
    -moz-animation: bg-animation 10s ease-in infinite;
    animation: bg-animation 10s ease-in infinite;
}

@keyframes bg-animation {
    0%,
    100% {
        background-position: 100% 0%;
    }
    
    50% {
        background-position: 0% 100%;
    }
}


/* -------------------- React confirm css ---------------------------- */
/* 
body.react-confirm-alert-body-element {
	overflow: hidden;
}

.react-confirm-alert-blur {
	filter: url(#gaussian-blur);
	filter: blur(2px);
	-webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(0, 0, 0, 0.7);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
	font-family: Arial, Helvetica, sans-serif;
	width: 400px;
	padding: 30px;
	text-align: left;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
	color: #666;
}

.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}

.react-confirm-alert-body>h1 {
	margin-top: 0;
}

.react-confirm-alert-body>h3 {
	margin: 0;
	font-size: 16px;
}

.react-confirm-alert-button-group {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.react-confirm-alert-button-group>button {
	outline: none;
	background: #333;
	border: none;
	display: inline-block;
	padding: 6px 18px;
	color: #eee;
	margin-right: 10px;
	border-radius: 5px;
	font-size: 12px;
	cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-moz-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-o-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
 */


 .font-qoute{
	font-family: 'Special Elite', cursive;
 }












 /* Reset css */
 h1,h2,h3,h4,h5,h6{
  text-wrap: balance;
 }

 p, li, figcaption{
  text-wrap: pretty;
  /* max-width: 65ch; */
 }

 header, footer, section, article, aside, nav, main{
  container-type: inline-size;
 }


 .Highlight__part{
   background-color: rgba(255, 0, 0, 0.5) !important;
 }



 .loader {
  width: 200px;
  height: 140px;
  background: #979794;
  box-sizing: border-box;
  position: relative;
  border-radius:8px;
  perspective: 1000px;
}

.loader:before{
  content: '';
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius:8px;
  background: #f5f5f5  no-repeat;
  background-size: 60px 10px;
  background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
  
  background-position: 15px 30px , 15px 60px , 15px 90px, 
            105px 30px , 105px 60px , 105px 90px;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
}
.loader:after {
  content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    transform: rotateY(0deg );
    transform-origin: left center;
  animation: paging 1s linear infinite;
}


@keyframes paging {
  to {
    transform: rotateY( -180deg );
  }
}



.site-loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}
.site-loader:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #000;
    opacity: 0.25;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.5s linear infinite;
}
.site-loader:after {
    content: '';
    width: 100%;
    height: 100%;
    @apply dark:bg-white bg-black;
    animation: bxSpin 0.5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
}
@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}





.gear-loader  {
  width: 60px;
  height: 40px;
  position: relative;
  display: inline-block;
  --base-color: #263238; /*use your base color*/
}
.gear-loader::before {
  content: '';  
  left: 0;
  top: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  @apply dark:bg-white bg-black;
  background-image: radial-gradient(circle 8px at 18px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 0px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 36px 18px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 18px 36px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 30px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 30px, var(--base-color) 100%, transparent 0), radial-gradient(circle 4px at 5px 5px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 3s linear infinite;
}
.gear-loader::after {
  content: '';  
  left: 35px;
  top: 15px;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  @apply dark:bg-white bg-black;
  background-image: radial-gradient(circle 5px at 12px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 0px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 0px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 24px 12px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 12px 24px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 20px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 20px, var(--base-color) 100%, transparent 0), radial-gradient(circle 2.5px at 3px 3px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack 4s linear infinite reverse;
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 

.FlashcardArrayWrapper__controls button svg{
  /* background-color: white; */
  @apply dark:stroke-white
}